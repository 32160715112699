<!-- Docs

Props:
1- Design Cell
  title => String
  icon => (class of line awesome)

2- showIf => condition must be a true to show cell

3- Routes
  ifNavigate => Boolean
  routerName => String
  routerPath => String
  routerParams => String

4- takeAction ($emit)
   takeActionParams => (get value of key in row)

5- change Status (with api)
-->
<template>
  <div>
    <div
      v-if="cellActions.actions.length"
      class="d-flex justify-content-start w-full gap_1"
    >
      <!-- Loop over actions -->
      <span v-for="(action, key) in cellActions.actions" :key="key">
        <span v-if="action.showIf ? action.showIf(dataItem) : true">
          <div v-if="action.isCheckbox" class="isChecked">
            <div class="attended">
              <input
                class="mx-2"
                id="attendance"
                type="checkbox"
                v-model="isChecked"
                @change="toggleCheckbox(action, 'attendance')"
              />
              <span>تم الحضور</span>
            </div>
            <div class="not-attended">
              <input
                class="mx-2"
                id="not-attendance"
                type="checkbox"
                v-model="notAttended"
                @change="toggleCheckbox(action, 'not-attendance')"
              />
              <span>لم يتم الحضور</span>
            </div>
          </div>
          <div v-else>
            <!-- Display icon button -->
            <i
              v-b-tooltip.top="`${action.text}`"
              :class="[action.icon, `text-${action.color}`]"
              @click="fireAction(action)"
            />
          </div>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import EventBus from "@/eventBus";
import doctorApi from "../../modules/hospitalDashboard/settings/services/settings";

export default {
  props: {
    cellActions: {
      type: Object,
    },
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      isChecked: this.dataItem.attended === true,
      notAttended: this.dataItem.attended === false,
    };
  },
  methods: {
    fireAction(action) {
      if (action.ifNavigate) {
        let params = {};
        let path = action.routePath || null;

        const actionParams = action.routeName || null;
        if (action.getParams) {
          params = action.getParams(this.dataItem);
        } else if (action.routePath) {
          const pathParams = action.routePath
            .split("/")
            .filter((e) => e.includes(":"))
            .map((e) => e.replace(":", ""));
          pathParams.forEach((e) => {
            params[e] = this.dataItem[e];
            path = path.replace(`:${e}`, this.dataItem[e]);
          });
        } else if (Array.isArray(actionParams)) {
          actionParams.forEach((e) => {
            params[e] = this.dataItem[e];
          });
        } else
          params[actionParams || "id"] = this.dataItem[actionParams || "id"];
        this.$router.push(
          path || {
            name: action.routeName,
            params,
          }
        );
      } else if (action.actionName) {
        let objData = {}
        if (action.actionParams === 'fullObj') {
          objData = this.dataItem

        } else if (action.actionParams) {
          action.actionParams.forEach((params) => {
            if (this.dataItem[params]) {
              objData[params] = this.dataItem[params];
            }
          });
        }
        this.$root.$emit(action.actionName, objData);
      } else if (action.showAlert) {
        EventBus.$emit("openDeleteModal", {
          actionHeader: action.actionHeader ? action.actionHeader : "مسح",
          titleHeader: action.titleHeader ? action.titleHeader : "",
          textContent: action.textContent
            ? this.dataItem[action.textContent]
            : "",
          url: action.url ? action.url : "",
          rowId: this.dataItem.id,
          question: action.question
            ? action.question
            : `Are You Sure You Want Delete This ${action.titleHeader} ?`,
          textDeleteButton: action.textDeleteButton
            ? action.textDeleteButton
            : `YES, ${action.actionHeader}`,
          textCancelButton: action.textCancelButton
            ? action.textCancelButton
            : "NO, CANCEL",
          icon: action.iconClass ? action.iconClass : "las la-trash-alt",
          type: action.type ? action.type : "delete",
          actionOnAlert: action.actionOnAlert ? action.actionOnAlert : "",
        });
      }
    },
    toggleCheckbox(action, checkboxType) {
      if (checkboxType === "attendance") {
        this.notAttended = !this.isChecked;
      } else if (checkboxType === "not-attendance") {
        this.isChecked = !this.notAttended;
      }

      const status =
        checkboxType === "attendance" && this.isChecked
          ? "attended"
          : "not_attended";

      console.log("Data Item:", this.dataItem);
      console.log("Data Item ID:", this.dataItem.id);

      const itemId = +this.dataItem.id;

      if (!isNaN(itemId)) {
        console.log("Valid Item ID:", itemId);
        doctorApi
          .attendStatus({
            status: status,
            itemId: itemId,
          })
          .then((response) => {
            console.log("Successfully updated attendance status:", response);
          })
          .catch((error) => {
            console.error("Error updating attendance status:", error);
          });
      } else {
        console.error("Invalid item ID:", itemId);
      }
    },
  },
  mounted() {
    console.log("Data Item on Mount:", this.dataItem);
    console.log(this.dataItem.attended);
  },
};
</script>

<style scoped>
.isChecked {
  display: flex;
  flex-direction: column;
}
</style>
