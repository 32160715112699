/*eslint-disable*/
import { veterainary, website } from "@/axios";

export default {
  getClinicInfo() {
    return veterainary().get("clinics");
  },
  updateClinicInfo(payload) {
    return veterainary().patch("clinics", payload);
  },
  getRequiredDocuments() {
    return veterainary().get("acceptance-docs?required_for=clinics");
  },
  uploadDotorDocument(payload) {
    return website().post("upload", payload);
  },
  updateDoctorDocuments(payload) {
    return veterainary().post("doctors/documents", payload);
  },
  updateClinicsDocuments(payload) {
    return veterainary().post("clinics/documents", payload);
  },
  getDcotorReservation() {
    return veterainary().get("doctor/reservations");
  },
  attendStatus(reservation) {
    return veterainary().patch(
      `doctor/reservations/attended/${reservation.itemId}`,
      { attended: reservation.status === "attended" ? true : false }
    );
  },
  getAllCities() {
    return website().get("cities");
  },
  requestWithdraw(payload) {
    return veterainary().post("clinics/withdrawals", payload);
  },
};
